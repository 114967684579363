<template>
  <v-container fluid>
    <v-row>
            <v-col :align="'center'">
              <h1>{{ $t('faq.faqTitle') }}</h1>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-spacer v-if="$vuetify.breakpoint.xl"></v-spacer>
            <v-col :align="'center'" xl="8">
              {{ $t('faq.faqDescription') }}
            </v-col>
            <v-spacer v-if="$vuetify.breakpoint.xl"></v-spacer>
        </v-row>
    <v-row no-gutters>
      <v-col>
        <v-expansion-panels>
          <v-row no-gutters class="my-5">
            <v-col :align="'center'">
              <h2>
                {{ $t('faq.getStartedTitle') }}
              </h2>
            </v-col>
          </v-row>
          <v-expansion-panel v-for="getStartedItem in getStartedItems" :key="getStartedItem.id" :active-class="'accent--text'">
            <v-expansion-panel-header>
              <b>{{ getStartedItem.title }}</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p v-html="getStartedItem.content"></p>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-row no-gutters class="my-5">
            <v-col :align="'center'">
              <h2>
                {{ $t('faq.securityTitle') }}
              </h2>
            </v-col>
          </v-row>
          <v-expansion-panel v-for="securityItem in securityItems" :key="securityItem.id" :active-class="'accent--text'">
            <v-expansion-panel-header>
              <b>{{ securityItem.title }}</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p v-html="securityItem.content"></p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-row no-gutters class="my-5">
            <v-col :align="'center'">
              <h2>
                {{ $t('faq.supportTitle') }}
              </h2>
            </v-col>
          </v-row>
          <v-expansion-panel v-for="supportItem in supportItems" :key="supportItem.id" :active-class="'accent--text'">
            <v-expansion-panel-header>
              <b>{{ supportItem.title }}</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p v-html="supportItem.content"></p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
  name: 'faq-page',
  data() {
    return {

    }
  },
  computed: {
    getStartedItems() {
      return [
        {
          id: 'gs-1',
          title: this.$t('faq.getStartedHowTo'),
          content: this.$t('faq.getStartedHowToDescription')
        },
        {
          id: 'gs-2',
          title: this.$t('faq.getStartedChains'),
          content: this.$t('faq.getStartedChainsDescription')
        },
        {
          id: 'gs-3',
          title: this.$t('faq.getStartedImportData'),
          content: this.$t('faq.getStartedImportDataDescription')
        },
        {
          id: 'gs-4',
          title: this.$t('faq.getStartedMetamask'),
          content: this.$t('faq.getStartedMetamaskDescription')
        },
        {
          id: 'gs-5',
          title: this.$t('faq.getStartedRemove'),
          content: this.$t('faq.getStartedRemoveDescription')
        },
        {
          id: 'gs-6',
          title: this.$t('faq.getStartedLargeWallets'),
          content: this.$t('faq.getStartedLargeWalletsDescription')
        }
      ]
    },
    securityItems() {
      return [
        {
          id: 'se-1',
          title: this.$t('faq.securityInformationPrivate'),
          content: this.$t('faq.securityInformationPrivateDescription')
        },
        {
          id: 'se-2',
          title: this.$t('faq.securityCollectData'),
          content: this.$t('faq.securityCollectDataDescription')
        },
      ]
    },
    supportItems() {
      return [
        {
          id: 'su-1',
          title: this.$t('faq.supportProblem'),
          content: this.$t('faq.supportProblemDescription')
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss"></style>